/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
import React, {useRef, useEffect, useState, Suspense} from 'react';
// import Arrow from '../../public/images/endwrapper_bg_2_arrow.svg';


const EndingPage = () => {


    useEffect(() => {
        // Initialize gsap animations
        
    }, []);


    return (
        <div className="autoX__endingPage">

            <section className="autoX__hero autoX__hero--endPage">
                <div className="container">

                    <div className="autoX__heroColumn autoX__heroColumn--top">
                        <div className="autoX__heroTitle">
                            <h1>Algorithmic trading under your command</h1>
                        </div>
                        <div className="autoX__heroBody">
                            <h2>Built by traders for traders. Delivered how you want.</h2>
                            <p>A sophisticated approach to the market with a flexible workflow and customization capabilities, setting a new standard for algorithmic trading in fixed income. Built by traders, for traders and delivered how you want.</p>
                        </div>

                        <a href="/lp/autox/landing" className="autoX__ctaButton autoX__ctaButton--link">Try a different strategy<span className="autoX__ctaButtonArrow"></span></a>
                        <a href="/lp/autox/contact-us" className="autoX__ctaButton autoX__ctaButton--contact">Contact Us<span className="autoX__ctaButtonArrow"></span></a>
                    </div>

                    <div className="autoX__heroColumn autoX__heroColumn--text">
                        <div className="autoX__heroInfoBox">
                            <img src="/images/realtime.svg" alt="Real Time Market Trasparency Icon" />
                            <h3>REAL-TIME MARKET TRANSPARENCY</h3>
                            <p>See into executions and live orders with the ability to pause and stop while in flight. Preserve the integrity of your orders with guardrails for market and price, including kill switches and other risk controls.</p>
                        </div>
                        <div className="autoX__heroInfoBox">
                            <img src="/images/customize.svg" alt="Customize and Scale Up Icon" />
                            <h3>CUSTOMIZE & SCALE UP</h3>
                            <p>Create your own custom parameter sets that work dynamically with your trading objectives and can be shared with your team. Respond to changing market conditions to reflect your intentions.</p>
                        </div>
                        <div className="autoX__heroInfoBox">
                            <img src="/images/access.svg" alt="Ways to Access Icon" />
                            <h3>MANY WAYS TO ACCESS</h3>
                            <p>Determine your strategy and parameters via Adaptive Auto-X user interface, or directly from your O/EMS.</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="autoX__contactUs">
                <img src="/images/endwrapper_bg_2_arrow.svg" alt="Background Decoration" />
                <div className="container">

                    <div className="autoX__contactUsContent">
                        <h2>Contact MarketAxess to learn more about how your trading can benefit from Adaptive Auto-X.</h2>
                        <a href="/lp/autox/contact-us" className="autoX__contactUsCta">Contact Us</a>
                    </div>
                </div>
            </section>

        </div>
    );
    
}

export default EndingPage;
