import React, {useRef, useEffect, useState, Suspense} from 'react';
import { useLocation } from 'react-router-dom'

const Header = () => {

  const location = useLocation();
  useEffect(() => {
    // Update progress of header
    if(location.pathname.includes('strategy/')) {
      const steps = document.getElementsByClassName('autoX__headerProgressStep');
      if(steps && steps.length) {
        steps[1].classList.add('autoX__headerProgressStep--active');
        steps[2].classList.add('autoX__headerProgressStep--active');
        steps[1].querySelector('.autoX__headerProgressLine').classList.add('autoX__headerProgressLine--completed');
      }
    } else if(location.pathname.includes('/ending')) {
      const steps = document.getElementsByClassName('autoX__headerProgressStep');
      if(steps && steps.length) {
        steps[1].classList.add('autoX__headerProgressStep--active');
        steps[2].classList.add('autoX__headerProgressStep--active');
        steps[3].classList.add('autoX__headerProgressStep--active');
        steps[4].classList.add('autoX__headerProgressStep--active');
        steps[1].querySelector('.autoX__headerProgressLine').classList.add('autoX__headerProgressLine--completed');
        steps[2].querySelector('.autoX__headerProgressLine').classList.add('autoX__headerProgressLine--completed');
        steps[3].querySelector('.autoX__headerProgressLine').classList.add('autoX__headerProgressLine--completed');
      }
    }

    window.addEventListener('load', () => {
      // get header element
      const headerProgressLine = document.getElementById('autoX__header');
      let headerHeight = window.innerWidth <= 1024 ? 0 : headerProgressLine.offsetHeight - 1;

      const primaryHeader = document.querySelector('.site-header');
      headerProgressLine.style.top = primaryHeader && window.innerWidth <= 1024 ? `${primaryHeader.offsetHeight - 1}px` : null;

      const headroomWrapper = document.querySelector('.headroom-wrapper');
      if (headroomWrapper && primaryHeader) headroomWrapper.style.height = `${primaryHeader.offsetHeight}px`;

      // get element with class name autoX__strategySidebar
      const sideBar = document.querySelector('.autoX__strategySidebar');
      const strategyContent = document.querySelector('.autoX__strategyContent');
      const strategyPage = document.querySelector('.autoX__strategyPage');
      if(sideBar) {
        sideBar.style = "height: calc(100vh - " + headerHeight + "px)";
        strategyContent.style = "height: calc(100vh - " + headerHeight + "px)";
        strategyPage.style = "padding-top: " + headerHeight + "px";
      }
      const windowWidth = window.innerWidth;
      const landingPage = document.querySelector('.autoX__landingPage');
      if(landingPage && windowWidth > 1024) {
        landingPage.style = "padding-top: " + headerHeight + "px";
      }

      const endingPage = document.querySelector('.autoX__endingPage');
      if(endingPage) {
        endingPage.style = "padding-top: " + headerHeight + "px";
      }
    });

    window.addEventListener('resize', () => {
      // get header element
      const headerProgressLine = document.getElementById('autoX__header');
      let headerHeight = window.innerWidth <= 1024 ? 0 : headerProgressLine.offsetHeight - 1;

      const primaryHeader = document.querySelector('.site-header');
      headerProgressLine.style.top = primaryHeader && window.innerWidth <= 1024 ? `${primaryHeader.offsetHeight - 1}px` : null;

      const headroomWrapper = document.querySelector('.headroom-wrapper');
      if (headroomWrapper && primaryHeader) headroomWrapper.style.height = `${primaryHeader.offsetHeight}px`;

      // get element with class name autoX__strategySidebar
      const sideBar = document.querySelector('.autoX__strategySidebar');
      const strategyContent = document.querySelector('.autoX__strategyContent');
      const strategyPage = document.querySelector('.autoX__strategyPage');
      if(sideBar && strategyContent && strategyPage) {
        sideBar.style = "height: calc(100vh - " + headerHeight + "px)";
        strategyContent.style = "height: calc(100vh - " + headerHeight + "px)";
        strategyPage.style = "padding-top: " + headerHeight + "px";
      }

      const windowWidth = window.innerWidth;
      const landingPage = document.querySelector('.autoX__landingPage');
      if(landingPage && windowWidth > 1024) {
        landingPage.style = "padding-top: " + headerHeight + "px";
      } else if(landingPage) {
        landingPage.style = "padding-top: 0";
      }

      const endingPage = document.querySelector('.autoX__endingPage');
      if(endingPage) {
        endingPage.style = "padding-top: " + headerHeight + "px";
      }
    });
  });

  return (
    <div className="autoX__header autoX__header--withPrimaryNav" id="autoX__header">
      <div className="container">

        <a className="autoX__headerLogoTarget" href="https://www.marketaxess.com/trade/auto-execution">
          <img src="/images/logo.png" alt="Marketaxess" className="autoX__headerLogo" />
        </a>

        <div className="autoX__headerProgress">

          <div className="autoX__headerProgressStep autoX__headerProgressStep--active">
            <div className="autoX__headerProgressStepWrapper">
              <span className="autoX__headerProgressStepNumber">1</span>
              <span className="autoX__headerProgressLine autoX__headerProgressLine--completed"><span className="autoX__headerProgressDot"></span></span>
            </div>
            <span className="autoX__headerProgressStepTitle">Adaptive Auto-X</span>
          </div>

          <div className="autoX__headerProgressStep autoX__headerProgressStep">
            <div className="autoX__headerProgressStepWrapper">
              <span className="autoX__headerProgressStepNumber">2</span>
              <span className="autoX__headerProgressLine autoX__headerProgressLine"><span className="autoX__headerProgressDot"></span></span>
            </div>
            <span className="autoX__headerProgressStepTitle">Select Strategy</span>
          </div>
          
          <div className="autoX__headerProgressStep autoX__headerProgressStep">
            <div className="autoX__headerProgressStepWrapper">
              <span className="autoX__headerProgressStepNumber">3</span>
              <span id="headerProgressLine" className="autoX__headerProgressLine autoX__headerProgressLine">
                <span id="headerProgressAnimatedLine" className="autoX__headerProgressAnimatedLine"></span>
                <span id="headerProgressDot" className="autoX__headerProgressDot"></span>
              </span>
            </div>
            <span className="autoX__headerProgressStepTitle">Selected Strategy</span>
          </div>

          <div className="autoX__headerProgressStep autoX__headerProgressStep" id="autoX__headerProgressStep4">
            <div className="autoX__headerProgressStepWrapper">
              <span className="autoX__headerProgressStepNumber">4</span>
              <span className="autoX__headerProgressLine autoX__headerProgressLine"><span className="autoX__headerProgressDot"></span></span>
            </div>
            <span className="autoX__headerProgressStepTitle">Trade Completed</span>
          </div>

          <div className="autoX__headerProgressStep autoX__headerProgressStep">
            <div className="autoX__headerProgressStepWrapper">
              <span className="autoX__headerProgressStepNumber">5</span>
            </div>
            <span className="autoX__headerProgressStepTitle">Finish!</span>
          </div>


        </div>

      </div>
    </div>
  );
}

export default Header;
